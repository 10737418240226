.header {
  $root: &;
  $transitionTime: .25s;
  $transitionDelay: .25s;
  $transition: $transitionTime cubic-bezier(.25, .46, .45, .94);
  position: relative;
  z-index: $indexHeader;

  &--hide {
    @media (min-width: $screen-tablet-portrait) {
      display: none;
    }
  }

  &--logo {
    #{$root}__logo-link {
      margin-left: 8px;

      @media (min-width: $screen-tablet-portrait) {
        margin-left: 32px;
      }
    }
  }

  &--search-active {
    z-index: $indexHeaderSearchActive;

    .native-app & {
      z-index: $indexAppNav;
    }
  }

  &--filters-open {
    z-index: 1;
  }

  &__top-bar {
    position: relative;
    z-index: 0;
    background: none;

    .header__sticky--active & {
      display: none;
    }
  }

  &__meta-menu {
    display: block;
  }

  &__sticky {
    background: var(--header-background);
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: box-shadow .3s ease-in-out;
    will-change: position;

    &--active {
      width: 100%;
      box-shadow: 0 0 5px 1px rgb(0 0 0 / .3);
      position: fixed;
      top: 0;
      left: 0;
    }
  }

  &__main {
    @include container();
    display: flex;
    max-width: none;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0 0 4px;
    position: relative;
    width: 100%;
    height: 40px;

    @media (min-width: $screen-mobile-large) {
      padding-left: 0;
      padding-right: 0;
    }

    @media (min-width: $screen-tablet-portrait) {
      padding-right: 20px;
    }

    @media (min-width: $screen-desktop-sm) {
      justify-content: flex-start;
      margin-top: 8px;

      .header__sticky--active & {
        min-height: 71px;
        padding-left: 3px;
        align-items: center;
      }
    }
  }

  &__logo-wrapper {
    .header__sticky--active & {
      @media (min-width: $screen-desktop-sm) {
        padding-left: 23px;
      }
    }
  }

  &__logo-link {
    display: inline-block;
    height: auto;
    margin: auto;
    transition: width 200ms ease-in-out;

    @media (min-width: $screen-mobile-sm) {
      height: auto;
    }

    @media (min-width: $screen-desktop-sm) {
      position: relative;
    }

    .header__main--type_checkout & {
      top: 6px;
    }

    .header__sticky--active & {
      @media (min-width: $screen-tablet-portrait) {
        float: none;
        margin: auto;
        width: 200px;
        left: 0;
      }

      @media (min-width: $screen-desktop-sm) {
        position: static;
        width: 200px;
        top: 12px;
      }
    }
  }

  &__logo {
    width: auto;
    height: 20px;
    display: block;

    @media screen and (min-width: $screen-mobile-sm) {
      height: 26px;
    }

    @media screen and (min-width: $screen-desktop) {
      height: 32px;
    }

    .header__sticky--active & {
      max-height: 45px;
    }
  }

  &__search-trigger,
  &__burger-menu-trigger {
    font-size: 23px;
    display: block;
    padding: 0 15px;
    text-align: center;
    position: relative;
    margin-bottom: -4px;
    cursor: pointer;

    .icon--mobileMenu::before {
      font-size: 20px;

      @media screen and (min-width: $screen-mobile) {
        font-size: 24px;
      }
    }
  }

  &__search-trigger {
    top: 2px;
    left: -10px;
    padding: 0;
    color: $whiteColor;
    display: unset;

    @media screen and (min-width: $screen-mobile) {
      top: 8px;
    }

    @media screen and (min-width: $screen-desktop-sm) {
      display: none;
    }
  }

  &__burger-menu {
    display: block;

    @media (min-width: $screen-desktop-sm) {
      display: none;
    }

    .header__sticky--active & {
      display: block;
    }

    &--open,
    .burger-menu--open & {
      transition-duration: .1s;
      transition-delay: $iconBurgerDelay;
      background: transparent;

      &::before {
        transition: $iconBurgerPseudoTransitionOpen;
        top: 0;
        transform: rotateZ(-45deg);
      }

      &::after {
        transition: $iconBurgerPseudoTransitionOpen;
        top: 0;
        transform: rotateZ(45deg);
      }
    }
  }

  &__search-icon {
    @include iconFont(search) {
      font-size: 20px;
      font-weight: $fontWeightBold;
      color: $blackColor;

      @media screen and (min-width: $screen-mobile) {
        font-size: 24px;
      }
    };

    @media (min-width: $screen-desktop-sm) {
      display: none;
    }

    .header__sticky--active & {
      display: block;
    }
  }

  &__user-shortcuts {
    position: relative;
    width: auto;
    margin: 0 5px 0 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @media screen and (min-width: $screen-mobile) {
      align-items: flex-start;
    }

    @media (min-width: $screen-tablet-portrait) {
      margin: 0 0 4px;
    }

    @media (min-width: $screen-desktop-sm) {
      z-index: 1;
      margin: 0 0 8px;
    }

    @media (min-width: $screen-desktop) {
      top: auto;
    }
  }

  &__user-shortcut {
    &--wishlist {
      @media (min-width: $screen-tablet-portrait) {
        display: block !important;
      }
    }
  }

  &__navigation {
    display: none;

    @media (min-width: $screen-desktop-sm) {
      display: block;
      width: 100%;
      margin: 0 0 4px;
      transition: opacity $transitionTime;
      transition-delay: $transitionDelay;
    }

    .header__sticky--active & {
      display: none;
    }

    .navigation__list > .navigation__element .navigation__link {
      color: $brandPrimaryColor;
      font-weight: $navigationLinkFontWeight;
      font-size: 11px;

      @media screen and (min-width: $screen-desktop) {
        font-size: 14px;
      }
    }

    &-container {
      display: flex;
      width: auto;
      position: absolute;
      margin-left: 150px;
      top: 0;

      @media (min-width: $screen-desktop) {
        margin-left: 170px;
      }
    }
  }

  &__search {
    margin: 20px auto 5px;
    width: 100%;
    display: block;
    position: relative;

    @media (min-width: $screen-mobile-sm) {
      margin: 12px auto 5px;
    }

    @media (min-width: $screen-tablet-portrait) {
      width: 70%;
      margin: 15px auto 10px;
    }

    @media (min-width: $screen-desktop-sm) {
      display: block;
      width: 300px;
      position: relative;
      margin: 0;
      transition: width $transition, right $transition;
      transition-delay: $transitionDelay;

      .header__sticky--active & {
        top: 0;
      }
    }

    &-container {
      flex-grow: 1;
      opacity: 0;
      margin-bottom: 0;
      width: 0;

      .input__field {
        cursor: default;

        @media (min-width: $screen-desktop-sm) {
          cursor: pointer;
        }
      }

      @media screen and (min-width: $screen-mobile-large) {
        width: auto;
      }

      @media (min-width: $screen-desktop-sm) {
        opacity: 1;
        display: flex;
        justify-content: flex-end;
        margin-bottom: -4px;
      }
    }

    .header__sticky--enabled.header__sticky--active & {
      @media (min-width: $screen-desktop-sm) {
        bottom: 0;
        right: 0;
        position: relative;
        margin: 0 0 0 62px;
      }
    }

    .search-autosuggest {
      height: 30px;

      @media screen and (min-width: $screen-desktop) {
        height: 40px;
      }

      &__input {
        padding: 0;
        margin-top: 0;
      }
    }

    .search-autosuggest__close {
      opacity: 0;
      transition: opacity .1s linear;
      transition-delay: $transitionTime + $transitionDelay;

      .header__sticky--active & {
        display: none;
      }
    }

    &.search-autosuggest--focus {
      width: 100%;

      .search-autosuggest__close {
        @media (min-width: $screen-desktop-sm) {
          @include iconFont(close);
          position: absolute;
          right: -43%;
          top: 40%;
          transform: translateY(-50%);
          cursor: pointer;
          opacity: 1;
          color: $whiteColor;
        }
      }
    }

    &--mobile {
      display: block;

      .header__search {
        width: 100%;
      }

      @media screen and (min-width: $screen-desktop-sm) {
        display: none;
      }
    }
  }

  &__product-flyout {
    width: calc(100vw - 30px);
    max-width: 320px;
    position: absolute;
    background: $backgroundWhiteColor;
    border-radius: 5px;
    top: 100%;
    margin-top: 10px;
    right: 0;
    padding: 20px;
    box-shadow: 0 0 5px rgb(0 0 0 / .3);
    z-index: $indexBasketFlyout;
  }

  &__checkout-process-breadcrumbs-wrapper {
    position: relative;
  }

  // Placeholder style
  /* stylelint-disable-next-line */
  .search-autosuggest__input .input__wrapper {
    height: 40px;
  }

  .search-autosuggest {
    .input__button {
      cursor: default;

      @media screen and (min-width: $screen-desktop) {
        cursor: pointer;
      }

      &-clear {
        cursor: default;

        @media screen and (min-width: $screen-desktop) {
          cursor: pointer;
        }
      }
    }
  }

  .user-shortcut__link {
    padding: 8px 5px 0;
    margin-bottom: 4px;

    @media screen and (min-width: $screen-tablet-portrait) {
      margin-bottom: unset;
    }

    &::before {
      font-size: 20px;

      @media screen and (min-width: $screen-mobile) {
        font-size: 24px;
      }
    }
  }

  &__mobile-search {
    @media screen and (min-width: $screen-desktop-sm) {
      display: none;
    }

    &--active {
      height: 50px;

      @media screen and (min-width: $screen-desktop-sm) {
        display: none;
      }
    }

    .search-autosuggest--mobile {
      width: 100% !important;
      margin-top: 0;
    }
  }
}
