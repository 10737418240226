@import '../../bps/variables/componentConfig.scss';

// Button
$buttonFontSizeMobile: 14px;
$buttonFontSizeDesktop: 14px;
$buttonSecondaryHeightMobile: 28px;
$buttonSecondaryHeightDesktop: 28px;
$buttonSecondaryLineHeightMobile: 25px;
$buttonSecondaryLineHeightDesktop: 25px;
$buttonFontWeight: $fontWeightMedium;
$buttonSmallFontWeight: $fontWeightMedium;
$buttonAddToBasketWidth: 80px;
$buttonAddToBasketHeight: 40px;
$buttonBackgroundColor: $greenDarkColor;
$buttonBorderColor: $whiteColor;
$buttonHoverBackgroundColor: darken($greenDarkColor, 10%);
$buttonHoverBorderColor: darken($whiteColor, 10%);
$buttonShadow: none;
$buttonShadowActive: none;
$buttonBorderWidth: 1px;
$buttonBorderRadius: 10px;

$buttonPrimaryBorderColor: rgb(222 223 92 / 1);
$buttonPrimaryBackgroundColor: rgb(222 223 92 / 1);
$buttonPrimaryTextColor: $greenDarkColor;
$buttonPrimaryHoverBackgroundColor: rgb(222 223 92 / .8);
$buttonPrimaryHoverBorderColor: rgb(222 223 92 / .8);
$buttonPrimaryHoverTextColor: $greenDarkColor;
$buttonPrimaryActiveBackgroundColor: rgb(222 223 92 / .7);

$buttonSecondaryBorderColor: $greenDarkColor;
$buttonSecondaryBackgroundColor: rgb(74 73 28 / 0);
$buttonSecondaryTextColor: $greenDarkColor;
$buttonSecondaryHoverBackgroundColor: rgb(74 73 28 / .2);
$buttonSecondaryHoverBorderColor: $greenDarkColor;
$buttonSecondaryHoverTextColor: $greenDarkColor;
$buttonSecondaryActiveBackgroundColor: rgb(74 73 28 / .3);

$buttonDisabledBackgroundColor: $bodyBackgroundColor;
$buttonDisabledBorderColor: rgb(74 73 28 / .5);
$buttonDisabledTextColor: rgb(74 73 28 / .5);

$buttonAddToBasketBackgroundColor: $greenLightColor;
$buttonAddToBasketBorderColor: $greenLightColor;
$buttonAddToBasketHoverBackgroundColor: darken($greenLightColor, 10%);

$buttonAboPrimaryBorderColor: $aboColor;
$buttonAboPrimaryBackgroundColor: $aboColor;
$buttonAboPrimaryHoverBackgroundColor: darken($aboColor, 10%);
$buttonAboSecondaryBorderColor: $whiteColor;
$buttonAboSecondaryBackgroundColor: $aboColor;
$buttonAboSecondaryTextColor: $whiteColor;

$buttonCounterWidth: 40px;
$buttonCounterHeight: 40px;
$buttonCounterWidthMobile: 45px;
$buttonCounterHeightMobile: 45px;
$buttonCounterBorderRadius: 10px;
$buttonCounterHideButtonPosition: 0;
$buttonCounterShowButtonPosition: -22px;
$buttonCounterLineHeightIncreaseButton: 30px;
$buttonCounterLineHeightDecreaseButton: 30px;
$buttonCounterBackgroundColor: $whiteColor;
$buttonCounterBorderColor: $brandPrimaryColor;
$buttonCounterTextColor: $textBaseColor;
$buttonCounterHoverBorderColor: darken($brandPrimaryColor, 20%);
$buttonCounterDisabledTextColor: $textBaseColor;
$buttonCounterDisabledBorderColor: $grayLighterColor;

// Grid Backgrounds
$gridBackgroundColor1: $whiteColor;
$gridBackgroundColor2: $bodyBackgroundColor;
$gridBackgroundColor3: $greenDarkColor;
$gridBackgroundColor4: $greenLightColor;
$gridBackgroundColor5: $greenLighterColor;
$gridElementTeaserBackground: #e7e0c3;
$gridElementTeaserTextColor: #000;

// consentCookieBox
$consentCookieBoxLogoWidth: 175px;
$consentCookieBoxButtonSecondaryHoverBackgroundColor: $buttonPrimaryHoverBackgroundColor;
$consentCookieBoxButtonSecondaryHoverTextColor: $buttonPrimaryHoverTextColor;
$consentCookieBoxButtonSecondaryBorderHover: 1px solid $buttonPrimaryBackgroundColor;
$consentCookieBoxButtonAcceptColor: $buttonPrimaryTextColor;
$consentCookieBoxButtonAcceptBackgroundColor: $buttonPrimaryBackgroundColor;
$consentCookieBoxButtonAcceptHoverBackgroundColor: $buttonPrimaryHoverBackgroundColor;
$consentCookieBoxButtonAcceptHoverTextColor: $buttonPrimaryHoverTextColor;
$consentCookieBoxButtonAcceptBorderHover: $buttonPrimaryBorderColor;

// Input
$inputBackgroundColor: $whiteColor;
$inputGrayBackgroundColor: #f5f5f5;
$inputGrayBackgroundColor: #f5f5f5;
$inputSuccessBackgroundColor: $whiteColor;
$inputErrorBackgroundColor: $whiteColor;
$inputFocusBackgroundColor: $whiteColor;
$inputBorderColor: $grayLightColor;
$inputFocusBorderColor: $blackColor;
$inputSuccessBorderColor: $blackColor;
$inputErrorBorderColor: $redDarkColor;
$inputShadow: 0 0 4px 0 transparentize($grayLightColor, .8);
$inputRadioLabelFontSize: $fontSizeFont;
$inputRadioLabelColor: $textBaseColor;
$inputRadioLabelCheckedColor: $textBaseColor;
$inputRadioCheckedColor: $greenDarkColor;
$inputRadioBackgroundColor: $whiteColor;
$inputPlaceholderColor: $blackColor;
$inputLabelColor: #8f8f8f;
$inputErrorFontSize: 14px;
$inputIconValidColor: $blackColor;
$inputFieldFontSizeMobile: 16px;

// Datepicker
$datePickerHeaderBackgroundColor: $greenDarkColor;
$datePickerHoverColor: $grayLightColor;
$datePickerCheckedColor: $greenDarkColor;
$datePickerIconColor: $blackColor;
$datePickerDayBackgroundColor: $whiteColor;
$datePickerArrowDisabledColor: #fff7;
$datePickerFontSize: 16px;
$datePickerWidth: 280px;

// form Headline
$formHeadlineFontWheight: $fontWeightRegular;
$formHeadlineFontSizeMobile: 32px;
$formHeadlineFontSizeDesktop: 40px;
$formHeadlineMarginMobile: 0 0 10px;
$formHeadlineMarginDesktop: 0;
$formHeadlineColor: $textBaseColor;

// checkoutStepDelivery
$deliveryOptionFormPriceDisplay: inline;
$checkoutStepDeliverySubHeadlineFontSize: 18px;
$checkoutStepDeliverySubHeadlineFontWeight: $fontWeightMedium;
$checkoutStepDeliverySublineFontSize: 16px;
$checkoutStepDeliverySublineFontWeight: $fontWeightRegular;

// checkoutStepSummary
$checkoutStepSummaryHeadlineFontSizeDesktop: 40px;
$checkoutStepSummaryHeadlineFontSizeMobile: 32px;
$checkoutStepSummaryBoxHeaderFontSizeMobile: 18px;
$checkoutStepSummaryBoxHeaderFontSizeDesktop: 18px;
$checkoutStepSummaryBoxTextFontSizeMobile: 16px;
$checkoutStepSummaryBoxTextFontSizeDesktop: 16px;
$checkoutStepSummaryTextLinkColor: $textBaseColor;
$checkoutStepSummaryBoxTextFontWeight: $fontWeightMedium;
$checkoutStepSummaryFooterTextColor: $textBaseColor;
$checkoutStepSummaryFooterTextFontSize: 16px;
$checkoutStepSummaryCheckboxMaxWidth: 590px;
$checkoutStepSummaryCheckboxLabelFontSizeMobile: 16px;
$checkoutStepSummaryCheckboxLabelFontSizeDesktop: 16px;
$checkoutStepSummaryCheckboxLabelLineHeight: 1.2;
$checkoutStepSummaryCheckboxDeclarationFontSize: 14px;
$checkoutStepSummaryShippingInfoDisplay: flex;

// creditcard
$creditcardInfotextDisplay: none;

// paymentMethodForm
$paymentMethodFormLabelFontWeight: $fontWeightMedium;
$paymentMethodFormLabelCheckedColor: $textBaseColor;
$paymentMethodFormEditTextTransform: lowercase;
$paymentMethodFormEditFontSize: $fontSizeXXSmall;
$paymentMethodCCOptionFontSize: $fontSizeXSmall;
$paymentMethodFormIframeBackground: #0000;

// deliveryOptionForm
$deliveryOptionFormLabelFontWeight: $fontWeightMedium;
$deliveryOptionFormLabelCheckedColor: $textBaseColor;
$deliveryOptionFormForecastTextFontSizeMobile: 12px;
$deliveryOptionFormForecastTextFontSizeDesktop: 16px;

// deliveryForecastMessage
$deliveryForecastMessageFontSizeMobile: 14px;
$deliveryForecastMessageFontSizeDesktop: 16px;

// checkout
$checkoutFontWeight: $fontWeightSemiBold;
$checkoutBoxBorderBottom: 1px solid $textBaseColor;
$checkoutBoxHeaderFontSize: 18px;
$checkoutBoxHeaderFontWeight: $fontWeightMedium;
$checkoutBoxContentFontSize: 16px;
$checkoutBoxButtonColor: $brandPrimaryColor;
$checkoutBoxButtonFontSize: 16px;
$checkoutBoxButtonFontWeight: $fontWeightMedium;
$checkoutBoxHeaderSidebarFontWeight: $fontWeightBold;

// orderSummarySmall
$orderSummarySmallFontSize: 16px;
$orderSummarySmallFontWeight: $fontWeightMedium;
$orderSummarySmallSmallTextFontWeight: $fontWeightRegular;
$orderSummarySmallSmallTextFontSize: 12px;
$orderSummaryTotalFontSize: 23px;
$orderSummarySmallTotalFontWeight: $fontWeightBold;

// infoPopup
$infoPopupBackground: $whiteColor;
$infoPopupColor: $textBaseColor;
$infoPopupFontSize: 14px;
$infoPopupLineHeight: 20px;
$infoPopupPadding: 16px 26px 16px 16px;
$infoPopupInfoBackground: $whiteColor;
$infoPopupInfoBorder: 1px solid $textBaseColor;
$infoPopupInfoFontSize: 14px;
$infoPopupTopMobile: 130px;

// Select
$selectLabelColor: $inputLabelColor;
$selectIconColor: $blackColor;
$selectTextColor: $textBaseColor;
$selectInactiveColor: $textBaseColor;
$selectActiveColor: $textBaseColor;
$selectCustomOptionBackground: $inputBackgroundColor;
$selectCustomOptionSelectedColor: $textBaseColor;
$selectCustomOptionHoverColor: $whiteColor;
$selectCustomOptionHoverBackground: $greenDarkColor;
$selectValuePadding: 0 35px 0 11px;
$selectCustomOptionsWrapperPadding: 5px 15px;
$selectFontWeightBold: $fontWeightThin;

// passwordInput
$passwordInputIconColor: $blackColor;
$passwordInputTextColor: $textBaseColor;

// CartPage
$cartPageHeaderMarginTablet: 40px auto 21px;
$cartPageHeaderMarginDesktop: 40px auto 21px;
$cartPageCurrencyFontSizeProduct: .8em;
$cartPageCurrencyFontSizeTotal: .8em;

// productOrderTable
$productOrderTableErrorBackgroundColor: #f2e5e5;
$productOrderTableErrorBorderColor: $errorColor;
$productOrderTableErrorTextColor: $errorColor;

// productOrderPosition
$productOrderPositionRemoveIcon: 'close';
$productOrderPositionPriceInfoOldValueFontSize: 12px;
$productOrderPositionPriceInfoOldValueMobileFontSize: 10px;
$productOrderPositionProductInfoPadding: 4px 0;

// product-order-free-item
$productOrderFreeItemBackgroundColorLegacy: #f5f5f5;
$productOrderFreeItemBackgroundColor: #f5f5f5;
$productOrderFreeItemHeadlineColor: $textDarkestColor;
$productOrderFreeItemNameColor: $textDarkestColor;
$productOrderFreeItemShortDescriptionColor: $textDarkestColor;
$productOrderFreeItemButtonSelectedColor: $successColor;
$productOrderFreeItemButtonSelectedBackgroundColor: $whiteColor;

// CounterInput
$buttonCounterBorderColor: $greenDarkColor;
$buttonCounterHoverBorderColor: $grayDarkColor;
$counterInputFontWeight: $fontWeightRegular;
$buttonCounterShowButtonPosition: -25px;

// ProductAwards
$productAwardTileBackground: $whiteColor;
$productAwardTileMinHeight: 295px;
$productAwardItemBackground: $whiteColor;
$productAwardArrowBottomColor: $whiteColor;
$productAwardTileTextColor: $blackColor;
$productAwardTileHeadlineColor: $blackColor;
$productAwardTileLinkColor: $blackColor;
$productAwardsCloseColor: $blackColor;
$dividerColor: $grayLightColor;

// productReviewsList
$productReviewsListReadMoreColor: $textBaseColor;
$productReviewsListFilterMarginMobile: -8px 0 0 -16px;
$productReviewsListFilterMarginDesktop: -4px 0 0 -16px;

// productReviews
$productReviewsBackground: $redDarkColor;
$productReviewsButtonBackground: transparent;
$productReviewsButtonHoverColor: $buttonSecondaryBackgroundColor;
$productReviewsButtonColor: $whiteColor;
$productReviewsSummaryStarSize: 32px;

// productReview
$productReviewBaseFontSize: $fontSizeSmall;
$productReviewTitleFontSize: 18px;
$productReviewTitleFontWeight: $fontWeightSemiBold;
$productReviewTitleTextColor: $blackColor;
$productReviewAuthorColor: $blackColor;
$productReviewTextFontWeight: $fontWeightRegular;

// productReviewHistogram
$productReviewHistogramLabelWidth: 72px;
$productReviewHistogramBarFillBackground: $redDarkColor;
$productReviewHistogramFontSize: .89em;

// checkbox
$checkboxWidth: 20px;
$checkboxBackground: $whiteColor;
$checkboxCheckedColor: $goldColor;
$checkboxCheckedBorderColor: $blackColor;
$checkboxLabelPaddingLeftMobile: 29px;
$checkboxLabelPaddingLeftDesktop: 29px;
$checkboxLabelLineHeight: 20px;
$checkboxLabelColor: $blackColor;
$checkboxBoxTop: 2px;
$checkboxBoxTransform: none;
$checkboxLabelFontSizeMobile: 16px;

// searchAutosuggest
$searchAutosuggestBackgroundColor: $whiteColor;
$searchAutosuggestHoverColor: $textHoverColor;
$searchAutosuggestHoverBorderColor: $brandPrimaryColor;
$searchAutosuggestSuggestionFontSize: 17px;
$searchAutosuggestSuggestionFontSizeMobile: 20px;
$searchAutosuggestSuggestionLineHeight: 17px;
$searchAutosuggestSuggestionFontWeight: $fontWeightThin;
$searchAutosuggestSuggestionColor: $textMediumGrayColor;
$searchAutosuggestHeadlineFontSize: $fontSizeSmall;
$searchAutosuggestHeadlineColor: $textDarkestColor;

// ServiceTeaser
$serviceHeadlineFontSize: 18px;
$serviceSublineFontSize: 18px;
$serviceIconColor: $textBaseColor;
$serviceHeadlineColor: $textBaseColor;
$serviceSublineColor: $textBaseColor;
$serviceTeaserBorder: 0;

// Carousel
$carouselButtonLeftIcon: 'arrowLeftBold';
$carouselButtonRightIcon: 'arrowRightBold';
$carouselPaginationFontSizeMobile: 18px;
$carouselPaginationBackgroundColor: rgb(72 72 36 / .5);
$carouselPaginationHoverBackground: rgb(72 72 36 / .7);
$carouselPaginationActiveBackground: #484824;

// Price
$priceColor: $greenDarkColor;
$priceDiscountColor: $redColor;

// navigation
$navigationElementColor: $textBaseColor;
$navigationElementBorderBottom: 2px solid transparent;
$navigationElementHoverColor: $textBaseColor;
$navigationElementHoverBorderBottom: 2px solid $goldColor;
$navigationLinkFontSize: 14px;
$navigationLinkFontWeight: $fontWeightThin;
$navigationFlyoutColor: $textBaseColor;
$navigationFlyoutHoverColor: $goldColor;
$navigationFlyoutAllColor: $textBaseColor;
$navigationFlyoutAllHoverColor: $goldColor;
$navigationFlyoutPadding: 20px 10px;
$navigationFlyoutListMargin: 5px 0 15px;

// $navigationFlyoutTeaser
$navigationFlyoutTeaserBackground: transparent;
$navigationFlyoutTeaserTextColor: $whiteColor;
$navigationFlyoutTeaserButtonDecoration: underline;
$navigationFlyoutTeaserButtonTransform: none;

// headerMetaContent
$headerMetaContentColor: $whiteColor;
$headerMetaContentFontWeight: $fontWeightMedium;
$headerMetaContentTextDecoration: none;
$headerMetaContentFontSize: 17px;
$headerMetaContentHoverColor: inherit;
$headerMetaContentHoverLineColor: $whiteColor;

// headerCheckout
$headerCheckoutBoxShadow: 0 0 5px 1px rgb(0 0 0 / .5);
$headerCheckoutBackground: $backgroundWhiteColor;
$headerCheckoutMobileMinHeight: 65px;
$headerCheckoutDesktopMinHeight: 65px;
$headerCheckoutMobileWidth: 140px;
$headerCheckoutDesktopWidth: 140px;
$headerCheckoutPadding: 16px;
$headerCheckoutSvgMobileHeight: 55px;
$headerCheckoutSvgDesktopHeight: 55px;
$headerCheckoutSvgMobileWidth: 55px;
$headerCheckoutSvgDesktopWidth: 55px;

// Footer
$footerServiceBackgroundColor: $whiteColor;
$footerServicePadding: 10px;
$footerServiceTextColor: $textBaseColor;
$footerServiceBlankColor: $textBaseColor;
$footerServiceDarkColor: #918b8b;
$footerServiceLinkColor: $textBaseColor;
$footerServiceFontSize: 14px;
$footerServiceHeadlinePadding: 8px 0;
$footerServiceHeadlineFirstTop: 0;
$footerServiceHeadlineFontSize: 14px;
$footerServiceLineHeight: 1.8;
$footerServiceLinkLineHeight: 1.8;
$footerServiceLogoPadding: 8px 0;
$footerServiceLogoWidth: 70px;
$footerServiceLogoHeight: 70px;
$footerServiceReadMoreMargin: 20px 0 0;
$footerServiceIconFontSize: 35px;
$footerServiceSpritePadding: 20px 0 10px;
$footerServiceIconMargin: 0 2px;
$footerServiceCheckoutMargin: 16px 0 !important;
$footerServiceCheckoutPadding: 0;

// Loader
$loaderBackgroundColor: #0000;

// goTop
$goTopWidthMobile: 50px;
$goTopWidthDesktop: 50px;
$goTopColor: $brandPrimaryColor;
$goTopBackgroundColor: $whiteColor;
$goTopBoxShadow: none;
$goTopHoverColor: $brandPrimaryColor;
$goTopHoverBackgroundColor: $whiteColor;
$goTopFontSizeMobile: 30px;
$goTopFontSizeDesktop: 34px;
$goTopIcon: arrowLineUp;
$goTopIconTextShadow: none;
$goTopIconRightMobile: 5px;

// checkoutPage
$checkoutPageBackground: #0000;
$checkoutPageBorder: $blackColor 1px solid;
$checkoutPagePaddingM: 16px 0;
$checkoutPagePaddingD: 24px 0;
$checkoutPagePreloaderBorderRadius: 10px;

// checkoutSteps
$checkoutStepsLabelFontSizeLegacy: $fontSizeMedium;
$checkoutStepsLabelTextTransformLegacy: uppercase;
$checkoutStepsActiveColorLegacy: $whiteColor;
$checkoutStepsActiveBorderLegacy: $redColor 4px dotted;

// productDetailedPage
$productDetailedPageBottomHeadlineFontSize: $fontSizeXXXLarge;
$productDetailedPageBottomHeadlineFontWeight: $fontWeightBold;

// productVariationInput
$productVariationInputOptionsBoxShadow: none;
$productVariationInputSelectPadding: 6px 10px 6px 12px;
$productVariationInputOptionsValueRowPadding: 6px 10px 6px 11px;
$productVariationInputOptionsValueRowBorderTop: 1px solid $blackColor;
$productVariationInputOptionsBackground: #fffbf4;
$productVariationInputOptionsHoverBackground: #f5d6a4;

// processTeaser
$processTeaserPadding: 20px 0;
$processTeaserWhitePadding: 20px;
$processTeaserBackground: transparent;
$processTeaserColor: $brandPrimaryColor;
$processTeaserWhiteBackground: $whiteColor;
$processTeaserWhiteColor: $textDarkestColor;
$processTeaserGrayBackground: $grayLighterColor;
$processTeaserButtonContainerBackground: $grayLighterColor;
$processTeaserFontSize: 16px;
$processTeaserFontSizeDesktop: 20px;
$processTeaserDividerColor: $textDarkestColor;
$processTeaserIconColor: $goldColor;
$processTeaserIconWhiteColor: $goldColor;
$processTeaserMaxWidth: 240px;

// Breadcrumbs
$breadcrumbsTextColor: $textDarkestColor;
$breadcrumbsSeparatorColor: $textDarkestColor;
$breadcrumbsLastItemFontWeigth: $fontWeightThin;
$breadcrumbsMargin: 16px auto;
$breadcrumbsLinkFontSize: $fontSizeXSmall;
$breadcrumbsLinkAfterMarginTop: -1px;

// Filters
/* stylelint-disable plugin/no-unsupported-browser-features */
$filtersFontSize: $fontSizeSmall;
$filtersFontWeight: $fontWeightRegular;
$filtersTextTransform: none;
$filtersLetterSpacing: 0;
$filtersIconColor: $brandPrimaryColor;
$filtersBorderColor: $grayLightColor;
$filtersFilterBorderTop: 1px solid $grayLightColor;
$filtersProductsSortBorder: 1px solid $grayLightColor;
$filtersProductsSortTextTransform: none;
$filtersArrowRightMobile: 15px;
$filtersArrowRightDesktop: 5px;
$filtersArrowFontSize: 21px;
$filtersArrowFontWeight: $fontWeightRegular;
$filtersLoaderColor: $brandPrimaryColor;
$filterBoxBorder: 1px solid $filterCheckboxColor;
$filterLabelFontSize: 16px;
/* stylelint-enable */

// headline
$headlineH1FontSize: 40px;
$headlineH2FontSize: 32px;
$headlineH2FontWeight: $fontWeightRegular;
$headlineH3FontSize: 28px;
$headlineH4FontSize: 24px;
$headlineH1TextTransform: none;
$headlineH2TextTransform: none;
$headlineH3TextTransform: none;
$headlineH4TextTransform: none;
$headlineSubLineMargin: 25px auto 10px;

// ScrollToContentButton
$scrollToContentButtonTransform: lowercase;
$scrollToContentButtonDecoration: underline;
$scrollToContentButtonLetterSpacing: normal;

// scrollAnimatedTeaser
$scrollAnimatedTeaserBorderRadius: 10px;

// Pagination
$paginationButtonFontSize: 20px;
$paginationSelectArrowFontSize: 14px;
$paginationBaseFontSize: 16px;
$paginationLabelFontSize: 16px;
$paginationSelectFontSize: 16px;
$paginationSelectArrowColor: $blackColor;
$paginationSelectFontWeight: $fontWeightSemiBold;

// Sort
$productsSortSelectArrowFontSize: 14px;
$productsSortLabelFontSize: 16px;

// Flags
$flagNewColor: transparent;
$flagAwardColor: transparent;
$flagStoererColor: $greenDarkColor;
$flagBioColor: #557b44;

// productsList
$productsListSpaceMobile: 10px;
$productsListSpaceTablet: 12px;
$productsListSpaceDesktop: 12px;

// ProductBox
$productBoxBorder: 1px solid $whiteColor;
$productBoxListBorder: 1px solid $whiteColor;
$productBoxWhishListBorder: 1px solid $whiteColor;
$productBoxListPadding: 14px;
$productBoxBackgroundColor: $whiteColor;
$productBoxNameColor: $textDarkestColor;
$productBoxShortDescriptionColor: $textDarkestColor;
$productBoxButtonWishlistColor: $greenDarkColor;
$productBoxWishlistColor: $greenDarkColor;
$productBoxLoadingColor: $brandPrimaryColor;
$productBoxStatusPadding: 16px 12px;
$productBoxStatusSuccessorBackgroundColor: $buttonAddToBasketBackgroundColor;
$productBoxStatusSuccessorColor: $buttonAddToBasketTextColor;
$productBoxLinkFontSize: $fontSizeXXSmall;
$productBoxWideBottleBackground: linear-gradient(180deg, #fff 0%, #e2e0e0 100%);
$productBoxStatusBorderRadius: 0;
$productBoxUnavailableTextTransform: none;
$productBoxLinkColor: $blackColor;

// Rating
$ratingStarsColor: $greenDarkColor;
$ratingEmptyStarsColor: #e1dbcb;
$ratingStarsHoverColor: darken($greenDarkColor, 10%);
$ratingStarsAboColor: #f5cc88;
$ratingEmptyStarsAboColor: #f2f1f1;
$ratingStarsAboHoverColor: darken(#f5cc88, 5%);
$ratingEmptyStarsAboSaleBoxColor: #c9c9c9;

// SaleBox
$saleBoxBasketHeaderBackground: $successBasketColor;

// ProductBoxBasket
$productBoxBasketHeaderBackground: $brandSecondaryColor;
$productBoxBasketContentBackground: #ebd2ab;

// FavoriteWineTeaser
$favoriteWineBackground: $brandTertiaryColor;

// treeNavigation
$treeNavigationBorderLeft: $blackColor 1px solid;
$treeNavigationHighlightColor: $blackColor;
$treeNavigationTextColor: $blackColor;
$treeNavigationFontSizeSecondary: 16px;
$treeNavigationFontWeight: $fontWeightSemiBold;
$treeNavigationFontWeightActive: $fontWeightBold;
$treeNavigationL1ItemHeight: 37px;
$treeNavigationL2ItemHeight: 37px;
$treeNavigationL3ItemHeight: 37px;
$treeNavigationItemPadding: 4px 0;
$treeNavigationItemPaddingMobile: 10px 0;

// siblingNavigation
$siblingNavigationBackground: linear-gradient(90deg, #f7f5f200 0%, $bodyBackgroundColor 100%);
$siblingNavigationColor: $blackColor;
$siblingNavigationActiveColor: $blackColor;
$siblingNavigationActiveFontWeight: $fontWeightMedium;
$siblingNavigationActiveBorderBottom: 2px $blackColor solid;
$siblingNavigationCountColor: $grayColor;
$siblingNavigationItemFontSize: $fontSizeSmall;

// origin-highlights
$originHighlightsMargin: -8px 8px 0 0;
$originHighlightsPadding: 5px 10px 7px;

// accountWelcomeView
$accountWelcomeViewBoxBorder: 0 none;

// userArea
$userAreaBoxContainerBorderMobile: none;
$userAreaBoxContainerBorderDesktop: 0 none;
$userAreaBoxHeadlineColor: $textDarkestColor;
$userAreaBoxHeadlineFontSize: 27px;
$userAreaBoxHeadlineFontWeight: $fontWeightBold;
$userAreaTextSecondaryColor: $textDarkestColor;
$accountNewsletterViewSubheadlineFontWeight: $fontWeightSemiBold;
$accountNewsletterViewSubheadlineEMailAddressFontWeight: $fontWeightSemiBold;
$accountNewsletterViewInfoTextPaddingBottom: 12px;

// accountSingleOrder
$accountSingleOrderTableHeadlineJustify: space-between;
$accountSingleOrderTableTextMargin: 0;

// singleOrderDetails
$singleOrderDetailsSectionNameColor: $textDarkestColor;
$singleOrderDetailsSectionNameFontWeight: $fontWeightBold;
$singleOrderDetailsStatusColor: $brandSecondaryColor;
$singleOrderDetailsStatusFontSize: $fontSizeXXSmall;
$singleOrderDetailsTrackingMaxWidth: 190px;
$singleOrderDetailsHeadAlign: center;

// SocialMedia
$socialMediaFontWeight: $fontWeightSemiBold;
$socialMediaLinkColor: $textDarkestColor;
$socialMediaLinkHoverColor: $textDarkestColor;

// WishlistShare
$wishlistShareTextPaddingBottom: 2px;

// wishlistDialog
$wishlistDialogInputBackground: $inputGrayBackgroundColor;
$wishlistDialogItemPadding: 8px 0;
$wishlistDialogItemPaddingMobile: 20px 0;

// quantityBox
$quantityBoxWidth: 166px;
$quantityBoxHeight: 128px;
$quantityBoxWidthMobile: 200px;
$quantityBoxHeightMobile: 150px;
$quantityBoxItemBgColor: $whiteColor;
$quantityBoxItemColor: $blackColor;
$quantityBoxItemOtherBgColor: $blackColor;
$quantityBoxItemOtherColor: $whiteColor;
$quantityBoxItemBgColorHover: #5dafd4;
$quantityBoxItemColorHover: $whiteColor;
$quantityBoxItemDisabledBgColor: $grayLighterColor;
$quantityBoxItemDisabledColor: $grayColor;
$quantityBoxBorderColor: $blackColor;

// inspirationList
$inspirationListMarginTop: -72px;
$inspirationListIntroTextAlign: center;

// accountOrdersList
$accountOrdersListHeadlineMargin: 40px 0 25px;

// VoucherForm
$voucherFormFontWeightBold: $fontWeightSemiBold;
$voucherFormInfoFontWeight: $fontWeightSemiBold;
$voucherFormInfoColor: $textDarkestColor;
$voucherFormInputBackground: #f5f5f5;

// accountAddresses
$accountAddressesBoxPrimaryBorderColor: $brandPrimaryColor;
$accountAddressesListMinHeight: 220px;

// accountRecurringOrderItem
$accountRecurringOrderItemBorder: 0 none;
$accountRecurringOrderItemBoxPadding: 16px;
$accountRecurringOrderItemBoxBackground: $whiteColor;
$accountRecurringOrderItemBoxItemsBackground: $bodyBackgroundColor;
$accountRecurringOrderItemAfterIcon: arrowDown;
$accountRecurringOrderItemAfterIconFontSize: 20px;
$accountRecurringOrderItemAfterIconTransform: translateY(4px);
$accountRecurringOrderItemAfterIconTransformOpen: translateY(4px) scale(-1);
$accountRecurringOrderItemPaddingItems: 6px;
$accountRecurringOrderItemSubmitOrder: 2;

// accountRecurringOrderShippingDate
$accountRecurringOrderShippingDateBackground: $bodyBackgroundColor;

// footerSeo
$footerSeoBorderTop: 0 none;
$footerSeoPadding: 0;

// directOrderTeaser
$directOrderTeaserInputBackgroundColor: $inputGrayBackgroundColor;

// Address Suggestion
$addressSuggestionColor: $inputTextColor;
$addressSuggestionBorderColor: $textDarkestColor;
$addressSuggestionBackgroundColor: $inputBackgroundColor;
$addressSuggestionHoverColor: $whiteColor;
$addressSuggestionHoverBackgroundColor: $goldColor;
$addressSuggestionSelectedFontWeight: $fontWeightSemiBold;

// addressForm
$addressFormRadiosBorderRadius: 10px;
$addressFormRadiosBorderChecked: 2px solid $inputRadioCheckedColor;

// similarWinesTeaser
$similarWinesTeaserHeadlineColor: $greenDarkColor;

// availabilityInfo
$availabilityInfoFontMobile: $fontSizeXXSmall;

// Tags
$tagPadding: 4px 8px;
$tagPaddingMobile: 16px;
$tagColor: $whiteColor;
$tagBorderColor: $greenDarkColor;
$tagBackgroundColor: $greenDarkColor;
$tagBackgroundHoverColor: $greenDarkColor;
$tagBorderRadius: 4px;
$tagHoverColor: $whiteColor;
$tagBorderHoverColor: $greenDarkColor;
$tagCloseColor: currentColor;

// similarWinesSuggestTeaser
$similarWinesSuggestTeaserTextColor: $aboColor;
$similarWinesSuggestTeaserBubbleColor: #6a0d24;
$similarWinesSuggestTeaserHeadlineSmallSize: 20px;
$similarWinesSuggestTeaserSearchIcon: 'similarSearch';
$similarWinesSuggestTeaserItemBoxShadowActive: 0 0 2px 1px $redDarkColor inset;
$similarWinesSuggestTeaserButtonClearButtonTop: 23px;

// rangeSlider
$rangeSliderFontSize: 16px;
$rangeSliderHandleRadius: 50%;

// productListPage
$productsListInspirationTabAnchorTopM: -130px;
$productsListInspirationTabAnchorTopD: -100px;

// generalMessageBorderRadius
$generalMessageBorderRadius: 10px;

// NewsletterPage
$newsletterPageSubHeadlineColor: $textBaseColor;
$newsletterPageEmailValueColor: $textBaseColor;
$newsletterPageTextSucessColor: $textBaseColor;

//sidePanel
$sidePanelBackground: #f1ede7;
$sidePanelCloseIconFontSize: 15px;

//slotMachine
$slotMachineTeaserBackgroundLeft: #ddd;
$slotMachineTeaserBackgroundRight: #ffe9e6;
$slotMachineBtnBackgroundColor: $whiteColor;
$slotMachineVoucherTextColor: #fb5b4f;

//SlotMachineColumn
$slotMachineColumnBorderColor: #ecb748;
$slotMachineColumnItemBorderColor: #c98b25;
